import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [showLanding, setShowLanding] = useState(true);
  const [copied, setCopied] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const audioRef = useRef(new Audio(`${process.env.PUBLIC_URL}/audio.mp3`));

  const backgroundImage = `${process.env.PUBLIC_URL}/background.png`;
  const backgroundMobileImage = `${process.env.PUBLIC_URL}/background-mobile.png`;
  const otherImages = [
    `${process.env.PUBLIC_URL}/4chan coom.jpg`, // Changed to .jpg
    `${process.env.PUBLIC_URL}/ahs coomer.png`,
    `${process.env.PUBLIC_URL}/born.png`,
    `${process.env.PUBLIC_URL}/convention coomer.jpg`, // Changed to .jpg
    `${process.env.PUBLIC_URL}/coom everywhere.jpg`, // Changed to .jpg
    `${process.env.PUBLIC_URL}/coomer background.jpg`,
    `${process.env.PUBLIC_URL}/coomer devil.jpg`,
    `${process.env.PUBLIC_URL}/coomer.jpg`, // Changed to .jpg
    `${process.env.PUBLIC_URL}/coomercommunity.jpg`,
    `${process.env.PUBLIC_URL}/coomerdemon.jpg`,
    `${process.env.PUBLIC_URL}/coomerfap.jpg`,
    `${process.env.PUBLIC_URL}/coomericon.png`,
    `${process.env.PUBLIC_URL}/coomernothing done.jpg`,
    `${process.env.PUBLIC_URL}/coomiest coomer.jpg`, // Changed to .jpg
    `${process.env.PUBLIC_URL}/cooomerri.jpg`,
    `${process.env.PUBLIC_URL}/creep .jpg`,
    `${process.env.PUBLIC_URL}/drowning in coom.png`,
    `${process.env.PUBLIC_URL}/female.jpg`,
    `${process.env.PUBLIC_URL}/gonna cum.gif`,
    `${process.env.PUBLIC_URL}/jack it.gif`,
    `${process.env.PUBLIC_URL}/keep cumming.gif`,
    `${process.env.PUBLIC_URL}/pledge coomer.jpg`, // Changed to .jpg
    `${process.env.PUBLIC_URL}/relax.jpg`,
    `${process.env.PUBLIC_URL}/repeat.jpg`,
    `${process.env.PUBLIC_URL}/sniff coom.jpg`, // Changed to .jpg
    `${process.env.PUBLIC_URL}/the 20 yr old coomer.png`,
  ];

  const eye1Image = `${process.env.PUBLIC_URL}/dexscreener.png`;
  const eye2Image = `${process.env.PUBLIC_URL}/pumplogo.png`;
  const noseImage = `${process.env.PUBLIC_URL}/raydium-removebg-preview.png`;
  const telegramImage = `${process.env.PUBLIC_URL}/telegramlogo.png`;
  const xImage = `${process.env.PUBLIC_URL}/xlogo.png`;
  const image3Image = `${process.env.PUBLIC_URL}/image3.jpg`;
  const enterImage = `${process.env.PUBLIC_URL}/enter.jpg`;
  const audioSrc = `${process.env.PUBLIC_URL}/audio.mp3`;

  const handleImageClick = (image) => {
    setShowModal(image);
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handleModalClose = () => {
    setShowModal(null);
  };

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 600);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleEnterClick = useCallback(() => {
    setShowLanding(false);
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

 const handleCopy = useCallback(() => {
    navigator.clipboard.writeText('DtAnj4E1AehBpN8UzDy8mExhamZAXGEMNg9dSzP2PGEX');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  if (showLanding) {
    return (
      <div className="landing-page" style={{ backgroundImage: `url(${enterImage})` }}>
        <button className="enter-button" onClick={handleEnterClick}>
          ENTER
        </button>
        <audio ref={audioRef} src={audioSrc} loop />
      </div>
    );
  }

  return (
    <div className="App">
    <div className="background-container">
      <img
        src={isMobile ? backgroundMobileImage : backgroundImage}
        alt="Background"
        className="background-image"
      />
    </div>
      <div className="content">
        <div className="icons-bar">
          <a href="https://dexscreener.com/solana/14gsu9r5dg5w1hnemeccf8mb9m76c8keerlazyarjgya" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={eye1Image} alt="Dexscreener Logo" className="icon-image" />
          </a>
          <a href="https://raydium.io/swap/?outputMint=DtAnj4E1AehBpN8UzDy8mExhamZAXGEMNg9dSzP2PGEX&inputMint=sol" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={noseImage} alt="Nose Logo" className="icon-image" />
          </a>
          <a href="https://t.me/+rJA4W2ZAUeQxMjJh" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={telegramImage} alt="Telegram Logo" className="icon-image" />
          </a>
          <a href="https://x.com/coomerCTO" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={xImage} alt="X Logo" className="icon-image" />
          </a>
        </div>
        <footer className="App-footer" onClick={handleCopy}>
          <div className="image3-container">
            <p className="contract-address">CA: DtAnj4E1AehBpN8UzDy8mExhamZAXGEMNg9dSzP2PGEX</p>
            {copied && <span className="copied-message">Copied!</span>}
          </div>
        </footer>
        <div className="image-boxes">
          {otherImages.map((image, index) => (
            <div
              key={index}
              className="image-box"
              onClick={() => handleImageClick(image)}
              style={{ '--delay': Math.random() * 1 }}
            >
              <img src={image} alt={`Image ${index}`} className="image-box-thumbnail" />
            </div>
          ))}
        </div>

     
        </div>
      {showModal && (
        <div className="modal" onClick={handleModalClose}>
          <img src={showModal} alt="Modal" className="modal-image" />
        </div>
      )}
      <audio ref={audioRef} src={audioSrc} loop />
    </div>
  );
}

export default App;